import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dateRange", "chartContainer", "loader"]

  connect() {
    this.initChart()
    this.fetchChartDataForRange(this.data.get("defaultRange"))
  }

  initChart() {
    var chartData = this.createChartData()
    this.chart = new Chart(this.chartContainerTarget, {
      type: "line",
      data: chartData,
      options: {
        "scales": {
          "yAxes": [{
            "ticks": {
              "beginAtZero": true
            }
          }]
        }
      },
      plugins: {},
    })
  }

  createChartData(payload = { labels: [], data: [] }) {
    return {
      "labels": payload.labels,
      "datasets": [{
        "label": this.data.get("label"),
        "backgroundColor": "#6777ef",
        "borderColor": "#6777ef",
        "data": payload.data,
        "borderWidth": 2.5,
        "pointBackgroundColor": "#ffffff",
        "pointRadius": 0
      }]
    }
  }

  rangeSelected(event) {
    event.preventDefault()

    const selectedDateRange = event.currentTarget.dataset.dateRange
    this.dateRangeTargets.forEach((el) => {
      let elDateRange = el.dataset.dateRange
      el.classList.toggle("active", elDateRange == selectedDateRange)
    })
    this.fetchChartDataForRange(selectedDateRange)
  }

  fetchChartDataForRange(selectedDateRange) {
    $(this.chartContainerTarget).hide()
    $(this.loaderTarget).show()
    const dataUrl = this.data.get("dataUrl")
    const that = this
    $.ajax({
      method: "GET",
      url: dataUrl,
      data: {
        date_range: selectedDateRange
      }
    })
    .done(function(payload) {
      that.showChart(payload)
    })
  }

  showChart(payload) {
    $(this.loaderTarget).hide()
    var chartData = this.createChartData(payload)
    this.chart.data = chartData
    this.chart.update()
    $(this.chartContainerTarget).show()
  }
}
