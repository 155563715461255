// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "bootstrap"
import "jquery.nicescroll"
import "moment"

import 'bootstrap-daterangepicker/daterangepicker'

import "select2"
import "izitoast"
import "cleave.js"
import "waypoints/lib/noframework.waypoints"
import "waypoints/lib/shortcuts/infinite"
import 'sticky-kit/dist/sticky-kit'
import "chart.js"

import "vendors/stisla/stisla"
import "vendors/stisla/scripts"


import "controllers"


import jquery from 'jquery'
window.jQuery = window.$ = jquery
top.jQuery = top.$ = jquery
