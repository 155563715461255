import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "status", "offlineAt"]

  connect() {
    this.checkIfEnableOfflineAt()
    $(this.statusTarget).select2().on('select2:select', () => this.checkIfEnableOfflineAt())

    this.element.setAttribute("novalidate", "true")
    $(this.element).submit(function() {
      var form = $(this);
      if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.addClass("was-validated");
    })
  }


  checkIfEnableOfflineAt() {
    const status = this.statusTarget.value

    if(status == 'offline') {
      this.enableOfflineAt()
    } else {
      this.disableOfflineAt()
    }
  }

  disableOfflineAt() {
    $(this.offlineAtTarget).parent().hide()
    $(this.offlineAtTarget).prop('disabled', true)
    $(this.offlineAtTarget).prop('required', false)
  }

  enableOfflineAt() {
    $(this.offlineAtTarget).parent().show()
    $(this.offlineAtTarget).prop('disabled', false)
    $(this.offlineAtTarget).prop('required', true)
  }
}
