import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "statusUpdateForm", "statusList", "statusDescription"]

  connect() {
  }

  onStatusUpdateSuccess(event) {
    let [data, status, xhr] = event.detail;

    this.statusListTarget.innerHTML += xhr.response
    this.statusDescriptionTarget.value = ""
    $(this.statusUpdateFormTarget).removeClass("was-validated");
  }

  onStatusUpdateError(event) {
    let [data, status, xhr] = event.detail;
    console.log(`xhr : ${xhr}`);
  }
}
