import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "deviceId", "submissionStatus", "approvedAt", "isContainer", "containerInstanceName" ]

  connect() {
    this.disableApprovedAt()
    $(this.submissionStatusTarget).select2().on('select2:select', () => this.dispatchChange())
    this.checkIfInstanceNameRequired()

    this.element.setAttribute("novalidate", "true")
    $(this.element).submit(function() {
      var form = $(this);
      if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.addClass("was-validated");
    })
  }

  checkIfInstanceNameRequired() {
    const isContainer = this.isContainerTarget.checked
    const submissionStatus = this.submissionStatusTarget.value
    if(isContainer && submissionStatus == 'approved') {
      $(this.containerInstanceNameTarget).prop('required', true)
    } else {
      $(this.containerInstanceNameTarget).prop('required', false)
    }
  }

  dispatchChange() {
    this.checkIfInstanceNameRequired()

    const submissionStatus = this.submissionStatusTarget.value

    if(submissionStatus == 'approved') {
      $(this.deviceIdTarget).prop('required', true)
      this.enableApprovedAt()
    } else {
      $(this.deviceIdTarget).prop('required', false)
      this.disableApprovedAt()
    }

  }

  disableApprovedAt() {
    $(this.approvedAtTarget).parent().hide()
    $(this.approvedAtTarget).prop('disabled', true)
    $(this.approvedAtTarget).prop('required', false)
  }

  enableApprovedAt() {
    $(this.approvedAtTarget).parent().show()
    $(this.approvedAtTarget).prop('disabled', false)
    $(this.approvedAtTarget).prop('required', true)
  }
}
