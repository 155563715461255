import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form", "submittingBtn", "notice", "submitBtn", "result"]

  connect() {
    $(this.submittingBtnTarget).hide()
    $(this.formTarget).submit(function() {
      var form = $(this);
      if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.addClass("was-validated");
    })
  }

  showNotice() {
    $(this.submittingBtnTarget).show()
    $(this.submitBtnTarget).hide()
    $(this.noticeTarget).show()
  }

  showResult() {
    let [data, status, xhr] = event.detail;
    this.resultTarget.innerHTML += xhr.response

    $(this.submittingBtnTarget).hide()
    $(this.noticeTarget).hide()

    $(this.submitBtnTarget).show()
    $(this.formTarget).removeClass("was-validated");
    this.formTarget.reset()
  }
}
